
import { defineComponent } from 'vue';
import Alert from '@/components/alerts/Alert.vue';
import MainLogo from '@/layout/header/partials/MainLogo.vue';

export default defineComponent({
  name: 'sign-up',
  components: {
    MainLogo,
    Alert,
  },
  computed: {
    hasRef() {
      return this.$route.query.ref;
    },
  },
  methods: {
    signIn() {
      this.$router.replace({
        name: 'sign-in',
        query: { ref: this.$route.query.ref },
      });
    },
    signUp() {
      this.$router.replace({
        name: 'sign-up',
        query: { ref: this.$route.query.ref },
      });
    },
  },
});
